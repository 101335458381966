import cn from 'classnames';
import { useTranslations } from 'next-intl';
import { MerchantLocationInput } from '@ee-monorepo/store-locator/feature-merchant-location-input';
import { useRef } from 'react';

export interface TopPanelProps {
  isLocationAvailable: boolean;
  city?: string;
  state?: string;
}

export const TopPanel = ({
  isLocationAvailable,
  city,
  state,
}: TopPanelProps) => {
  const t = useTranslations();
  return (
    <div data-testid="top-panel">
      <div className={cn('bg-SnapLightBlue', 'text-SnapDarkBlue')}>
        <div
          className={cn('snap-container', {
            'py-12': !isLocationAvailable,
            'pt-5 pb-7': isLocationAvailable,
          })}
        >
          <div
            className={cn('headingL md:headingXL')}
            data-testid="store-locator-find-store-text"
          >
            {t(`shared.store_locator_new_ui_top_panel.find_store_header`)}
          </div>
          <div
            className={cn('flex', 'gap-x-3', 'text-base', 'font-medium')}
            data-testid="store-locator-steps"
          >
            <div>
              <span className={cn('font-extrabold', 'text-lg')}>{'➀ '}</span>
              {t(`shared.store_locator_new_ui_top_panel.step1`)}
            </div>
            <div>
              <span className={cn('font-extrabold', 'text-lg')}>{'➁ '}</span>
              {t(`shared.store_locator_new_ui_top_panel.step2`)}
            </div>
            <div>
              <span className={cn('font-extrabold', 'text-lg')}>{'➂ '}</span>
              {t(`shared.store_locator_new_ui_top_panel.step3`)}
            </div>
          </div>
          {isLocationAvailable && (
            <div className={cn('mt-2', 'lg:w-[300px]')}>
              <MerchantLocationInput
                variant="storelocatornewui"
                initialLocation={{ city, state }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
