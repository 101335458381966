import cn from 'classnames';
import { useState, HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import s from './location-switch.module.scss';
import {
  selectHasResults,
  selectIndustry,
  selectIsContentAreaVisible,
  selectResultsCity,
  selectResultsState,
  useStoreLocatorActions,
} from '@ee-monorepo/store-locator/shared/data-access';
import { useTranslations } from 'next-intl';
import { useTablet } from '@ee-monorepo/shared/utilities/hooks';
import RoomIcon from '@mui/icons-material/Room';
import { ClickAwayListener, IconButton } from '@mui/material';
import { IconInfo, IconMap } from '@ee-monorepo/shared/ui/icons';
import {
  GeoLocatorButton,
  MerchantLocationInput,
} from '@ee-monorepo/store-locator/feature-merchant-location-input';
import { colors } from '@snap/colors';

interface LocationSwitchProps extends HTMLAttributes<HTMLElement> {
  isNewStoreLocator?: boolean;
}
export function LocationSwitch({
  className,
  isNewStoreLocator = false,
}: LocationSwitchProps) {
  const hasResults = useSelector(selectHasResults);

  const t = useTranslations();
  const isContentAreaVisible = useSelector(selectIsContentAreaVisible);
  const industry = useSelector(selectIndustry);
  const state = useSelector(selectResultsState);
  const city = useSelector(selectResultsCity);
  const isMobileDevice = useTablet();
  const { toggleContentArea } = useStoreLocatorActions();
  const [changeLocation, setChangeLocation] = useState(false);

  if (!hasResults) {
    return null;
  }
  return (
    <div className={className}>
      <div
        className={cn(
          'flex justify-between items-center w-full h-full',
          !isMobileDevice && s.stickyContent,
          s.industryLocationHeading,
          isNewStoreLocator ? 'py-2' : 'pt-2'
        )}
        data-testid="location-switch"
      >
        <div className="flex w-[calc(100%-30px)]">
          <h1
            className={cn(s.closestLocationHeading, 'bg-SnapWhite', 'bodyFont')}
          >
            {t.rich('shared.industry_and_location_heading', {
              industry: () => (
                <span className="bodyHeavyFont">
                  {t(`shared.industries.${industry}.singular`)}
                </span>
              ),
              cityAndState: () => (
                <span className="bodyHeavyFont">
                  {city}, {state}
                </span>
              ),
            })}
          </h1>

          <div
            className="flex ml-1 my-auto cursor-pointer"
            onClick={() => setChangeLocation(!changeLocation)}
          >
            <RoomIcon className="text-xl text-SnapBlue" />
            <span
              className="secondaryHeavyFont my-auto text-SnapBlue hover:text-SnapMediumBlue"
              data-testid="change-location"
            >
              {t('shared.change')}
            </span>
          </div>
        </div>

        {!isNewStoreLocator ? (
          <IconButton
            aria-label={
              isContentAreaVisible ? t('page.map.show') : t('page.map.hide')
            }
            className={cn('p-1.5 lg:hidden')}
            onClick={toggleContentArea}
            data-testid="toggle-map"
          >
            {isContentAreaVisible ? (
              <IconMap iconColor={colors.SnapBlue} />
            ) : (
              <IconInfo iconColor={colors.SnapBlue} />
            )}
          </IconButton>
        ) : null}
      </div>
      {changeLocation && (
        <ClickAwayListener
          onClickAway={(e) => {
            e.preventDefault();
            setChangeLocation(false);
          }}
        >
          <div
            data-testid="change-location-popper"
            className={cn(
              'absolute p-6 bg-white shadow-md ml-[5px] max-w-[100vw] w-[85%]',
              isNewStoreLocator ? 'md:w-[400px]' : 'md:w-[90%]',
              {
                hidden: !changeLocation,
              }
            )}
          >
            <div className="flex flex-col gap-3.5">
              {isNewStoreLocator ? (
                <span className="headingMHeavy">
                  {t('shared.set_store_location')}
                </span>
              ) : null}
              <MerchantLocationInput variant="invert" />
              <span className="block text-center bodyHeavyFont text-SnapGrey500">
                {t('shared.or')}
              </span>
              <div className="text-center">
                <GeoLocatorButton variant="outline" />
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}
