import { InStoreMerchantsResults } from '../../lazy/in-store-merchants-results';
import { MerchantsResultsFilters } from '../merchants-results-filters';
import { MerchantDetailContainer } from '../../lazy/merchant-detail-container';
import s from './store-locator-v1.module.scss';
import { MobileSheet } from '../../lazy/mobile-sheet';
import { Switches } from '../switches';
import {
  mapSheetBreaks,
  heroSheetBreaks,
  navAndToggleHeight,
} from '../constants/directorySheetBreaks';
import { SlicesArea } from '../prismic/slices-area';
import { MerchantCardHoverProvider } from '@ee-monorepo/store-locator/shared/ui';
import { InStoreInformationBlock } from '../information-block/in-store-information-block';
import Box from '@mui/material/Box';
import { LocationBreadCrumb } from '../location-bread-crumb/location-bread-crumb';
import { LocationSwitch } from '../location-switch';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import {
  selectErrorLoadingMerchants,
  selectHasResults,
  selectIsContentAreaVisible,
  selectWasSearched,
  useStoreLocatorActions,
  useStoreLocatorRouterActions,
} from '@ee-monorepo/store-locator/shared/data-access';
import { useEffect, useState } from 'react';
import { useTablet, useTouchMobile } from '@ee-monorepo/shared/utilities/hooks';
import { Slice } from '@prismicio/types';
import { useRouter } from 'next/router';
import { MOBILE_SHEET_SELECTOR } from '@ee-monorepo/shared/ui/mobile-sheet';
import { useStoreLocatorParams } from '../../../hooks/useStoreLocatorParams';
import { useUserLocation } from '../../../hooks/useUserLocation';

const StoreLocatorMap = dynamic(() => import('../store-locator-map'), {
  ssr: false,
});

export interface StoreLocatorV1Props {
  setSelectedLocationMock?: (merchantId: number) => void;
  slices: Slice[];
}

export function StoreLocatorV1({
  slices,
  setSelectedLocationMock,
}: StoreLocatorV1Props) {
  const router = useRouter();
  const [selectedLocation, setSelectedLocation] = useState(null);

  const { pageNumber } = router.query as {
    [key: string]: string;
  };
  const { query } = router;
  const { clear, callSearchMerchants } = useStoreLocatorActions();

  useEffect(() => {
    if (Object.keys(query).length === 0) {
      clear();
    }
  }, [query, clear]);

  useEffect(() => {
    callSearchMerchants();
  }, [callSearchMerchants]);

  const [selectedLocationOnHover, setSelectedLocationOnHover] = useState(null);
  const isMobile = useTouchMobile();
  const isMobileDevice = useTablet();
  const isContentAreaVisible = useSelector(selectIsContentAreaVisible);
  const searched = useSelector(selectWasSearched);
  const hasResults = useSelector(selectHasResults);
  const errorLoadingMerchants = useSelector(selectErrorLoadingMerchants);
  const [middleHeight, setMiddleHeight] = useState(0);
  const [isPushingGeoLocation, setIsPushingGeoLocation] = useState(true);

  // scroll top mobile sheet when page changes
  useEffect(() => {
    if (isMobile) {
      if (document.querySelector(MOBILE_SHEET_SELECTOR) != null)
        document.querySelector(MOBILE_SHEET_SELECTOR).scrollTop = 0;
    }
  }, [isMobile, pageNumber]);

  useEffect(() => {
    setTimeout(() => {
      if (isContentAreaVisible) {
        setMiddleHeight(
          window.innerHeight -
            navAndToggleHeight -
            document.querySelector('[data-testid="hero-secondary"]')
              ?.clientHeight ?? 0
        );
      } else {
        setMiddleHeight(window.innerHeight * 0.6);
      }
    }, 500);
  }, [isContentAreaVisible]);

  const { pushGeoCoordinates } = useStoreLocatorRouterActions();
  const { hasBaseParams, hasLatLong } = useStoreLocatorParams(
    setSelectedLocationMock || setSelectedLocation
  );
  const onGeoCoordsCallback = async ({
    latitude,
    longitude,
  }: GeolocationCoordinates) => {
    if (!hasBaseParams && isPushingGeoLocation) {
      pushGeoCoordinates({ latitude, longitude });
    }
  };
  useUserLocation({ onGeoCoordsCallback });
  useEffect(() => {
    if (hasLatLong && errorLoadingMerchants) {
      setIsPushingGeoLocation(false);
      router.push('/find-stores');
      clear();
    }
  }, [hasLatLong, errorLoadingMerchants]);

  return (
    <div data-testid="store-locator-v1">
      {isMobile && (
        <div className={cn(s.switchMobile)}>
          <Switches activeIndex={0} className="mb-3" />
        </div>
      )}
      {!isMobileDevice && <SlicesArea slices={slices} />}
      <div className={cn('md:shadow-[0px_-5px_8px_-2px_rgba(0,0,0,0.15)]')}>
        <div
          className={cn(
            s.storeLocatorBody,
            'mx-auto',
            !isMobileDevice && !hasResults && 'overflow-hidden'
          )}
        >
          {isMobile && (
            <MobileSheet
              show={isMobile}
              middleHeight={middleHeight}
              breaks={isContentAreaVisible ? heroSheetBreaks : mapSheetBreaks}
              initialBreakpoint={'middle'}
            >
              <div className="pb-6">
                {!searched && (
                  <Box data-testid="inStoreMerchantsResultsNoLocationSearch">
                    <InStoreInformationBlock />
                  </Box>
                )}
                <MerchantsResultsFilters />
                <LocationSwitch />
                <MerchantCardHoverProvider>
                  <InStoreMerchantsResults
                    className={cn('p-1')}
                    setSelectedLocationOnHover={setSelectedLocationOnHover}
                    selectedMerchantIdFromOutside={selectedLocationOnHover}
                  />
                  {searched && hasResults && <LocationBreadCrumb />}
                </MerchantCardHoverProvider>
              </div>
            </MobileSheet>
          )}
          {!isMobile && (
            <div data-testid="results-panel" className={cn(s.resultsPanel)}>
              <div
                className={cn('bg-SnapWhite', 'lg:mt-5', {
                  [s.stickyResultsPanel]: !isMobileDevice && hasResults,
                })}
              >
                <Switches activeIndex={0} className="mb-5" />
                {!searched && (
                  <Box data-testid="inStoreMerchantsResultsNoLocationSearch">
                    <InStoreInformationBlock />
                  </Box>
                )}
                <MerchantsResultsFilters />
                <LocationSwitch />
                <MerchantCardHoverProvider>
                  <InStoreMerchantsResults
                    className={cn(
                      'overflow-auto',
                      s.maxContentMerchant,
                      isMobileDevice && s.findStoresScrollable
                    )}
                    setSelectedLocationOnHover={setSelectedLocationOnHover}
                    selectedMerchantIdFromOutside={selectedLocationOnHover}
                  />
                  {searched && hasResults && <LocationBreadCrumb />}
                </MerchantCardHoverProvider>
              </div>
            </div>
          )}
          <MerchantDetailContainer
            className={cn(
              s.border,
              s.resultsPanel,
              s.maxResultContent,
              !isMobileDevice && s.stickyContent
            )}
          />
          <div
            className={cn(
              !isMobileDevice ? s.stickyContent : s.mapPanel,
              !isMobileDevice && !hasResults && 'position-static'
            )}
          >
            {isMobileDevice && isContentAreaVisible && (
              <SlicesArea
                slices={slices}
                className={cn({ 'overflow-hidden': !isMobile })}
              />
            )}
            {(!isMobileDevice || !isContentAreaVisible) && (
              <StoreLocatorMap
                setSelectedLocationOnHover={setSelectedLocationOnHover}
                selectedLocationOnHover={selectedLocationOnHover}
                selectedLocationOnClick={selectedLocation}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
