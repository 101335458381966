import { MerchantLocationInput } from '@ee-monorepo/store-locator/feature-merchant-location-input';
import Image from 'next/image';
import React from 'react';
import cn from 'classnames';
import { Button } from '@ee-monorepo/shared/ui/button';

interface StoreLocatorPlaceHolderBannerProps {
  imageSrc: string;
  imageHeight: number;
  imageWidth: number;
  imageAlt: string;
  showMerchantLocationInput: boolean;
  showActionButton: boolean;
  actionButtonText?: string;
  actionButtonCallback?: () => void;
  showBoldText: boolean;
  boldTextString?: string;
  primaryTextString: string;
  dataTestId?: string;
}

export const StoreLocatorPlaceHolderBanner = ({
  imageSrc,
  imageHeight,
  imageWidth,
  imageAlt,
  showMerchantLocationInput,
  showActionButton,
  actionButtonText,
  actionButtonCallback,
  showBoldText,
  boldTextString,
  primaryTextString,
  dataTestId,
}: StoreLocatorPlaceHolderBannerProps) => {
  return (
    <div
      className={cn(
        'flex',
        'h-full',
        'text-center',
        'place-self-center',
        'place-content-center',
        'place-items-center'
      )}
      data-testid={dataTestId}
    >
      <div>
        <div>
          <Image
            src={imageSrc}
            alt={imageAlt}
            height={imageHeight}
            width={imageWidth}
          ></Image>
        </div>
        {showBoldText && (
          <div className={cn('mt-4', 'text-2xl', 'font-bold')}>
            {boldTextString}
          </div>
        )}
        <div className={cn('mt-2', 'text-lg', 'font-normal')}>
          {primaryTextString}
        </div>
        {showMerchantLocationInput && (
          <div className={cn('w-[275px]', 'mt-2')}>
            <MerchantLocationInput variant="storelocatornewui" />
          </div>
        )}
        {showActionButton && (
          <div className={cn('mt-2')}>
            <Button variant="secondary" onClick={actionButtonCallback}>
              {actionButtonText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
