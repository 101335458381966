import React from 'react';
import { useTranslations } from 'next-intl';
import { MerchantLocationInput } from '@ee-monorepo/store-locator/feature-merchant-location-input';
import { Card } from '@ee-monorepo/shared/ui/card';

export const InStoreInformationBlock = () => {
  const t = useTranslations();
  return (
    <Card className="py-6" borderOnHover={false}>
      <h3
        className="bodyHeavyFont text-center"
        data-testid="inStoreInformationalBlock"
      >
        {t('page.store_information.in_store_information.title')}
      </h3>
      <p className="secondaryFont text-center text-SnapGrey500">
        {t('page.store_information.in_store_information.description')}
      </p>
      <div className="mt-5">
        <MerchantLocationInput />
      </div>
    </Card>
  );
};
