import {
  selectMerchant,
  selectResultsCity,
  selectResultsState,
  useStoreLocatorActions,
  useStoreLocatorRouterActions,
} from '@ee-monorepo/store-locator/shared/data-access';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import { buildOffsetUsingPageNumberInt } from '@ee-monorepo/shared/utilities/functions';
import { Industry } from '@ee-monorepo/shared/utilities/types';

const useStoreLocatorParamsV2 = () => {
  const router = useRouter();
  const { query } = router;
  const [previewsState, setPreviewsState] = useState('');
  const [previewsCity, setPreviewsCity] = useState('');

  const {
    searchMerchantsForLocation,
    searchIndustries,
    searchMerchants,
    clear,
    loadSingleMerchantAndResults,
    callSearchMerchants,
  } = useStoreLocatorActions();
  const { pushCityState } = useStoreLocatorRouterActions();
  const city = useSelector(selectResultsCity);
  const state = useSelector(selectResultsState);
  const merchant = useSelector(selectMerchant);

  const {
    zipCode,
    latitude,
    longitude,
    search: keyWord,
    industry,
    merchantId,
  } = query as {
    [key: string]: string;
  };

  useEffect(() => {
    if (Object.keys(query).length === 0) {
      clear();
    }
  }, [query, clear]);

  const hasLocationParams = !!zipCode || (!!latitude && !!longitude);

  const hasLatLong = !!latitude && !!longitude && !zipCode;

  //Initial call to Location, we are calling to get one merchant, then we take city and state from there
  useEffect(() => {
    if (latitude && longitude) {
      searchMerchantsForLocation({
        userCoordinates: {
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
        },
        offset: 0,
        limit: 1,
      });
    }
    if (zipCode) {
      searchMerchantsForLocation({
        zipCode: zipCode,
        offset: 0,
        limit: 1,
      });
    }
  }, [latitude, longitude, zipCode, searchMerchantsForLocation]);

  //update city and state in the url params
  useEffect(() => {
    if (state && city && state !== previewsState && city !== previewsCity) {
      pushCityState(state, city, '0');
      setPreviewsCity(city);
      setPreviewsState(state);
    }
  }, [pushCityState, city, state, previewsCity, previewsState]);

  /* Fetch industries based on the coordinates */
  useEffect(() => {
    if (latitude && longitude) {
      searchIndustries({
        userCoordinates: {
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
        },
      });
    }
  }, [latitude, longitude, searchIndustries]);

  /* Fetch industries based on the Zipcode */
  useEffect(() => {
    if (zipCode) {
      searchIndustries({
        zipCode: zipCode,
      });
    }
  }, [zipCode, searchIndustries]);

  //When industry, keyword or page number changes
  useEffect(() => {
    if (industry || keyWord) {
      if (latitude && longitude) {
        searchMerchants({
          userCoordinates: {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
          },
          offset: buildOffsetUsingPageNumberInt(1),
          limit: appConfig.merchantRetrievalLimit,
          industry:
            industry !== 'ALL' ? (industry?.toUpperCase() as Industry) : '',
          keyWord,
        });
      } else if (zipCode) {
        searchMerchants({
          zipCode: zipCode,
          offset: buildOffsetUsingPageNumberInt(1),
          limit: appConfig.merchantRetrievalLimit,
          industry:
            industry !== 'ALL' ? (industry?.toUpperCase() as Industry) : '',
          keyWord,
        });
      }
    }
  }, [industry, keyWord, latitude, longitude, zipCode, searchMerchants]);

  useEffect(() => {
    callSearchMerchants();
  }, [callSearchMerchants]);

  /* Specific Merchant */
  useEffect(() => {
    if (merchantId && (!merchant || merchant.id !== parseInt(merchantId)))
      loadSingleMerchantAndResults(merchantId);
  }, [merchantId, loadSingleMerchantAndResults, merchant]);

  return { hasLocationParams, hasLatLong, clear };
};

export { useStoreLocatorParamsV2 };
