import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from '@ee-monorepo/store-locator/shared/data-access';
interface UserLocationHookProps {
  onGeoCoordsCallback: (coords: GeolocationCoordinates) => void;
}

const useUserLocation = ({ onGeoCoordsCallback }: UserLocationHookProps) => {
  const { isReady } = useRouter();
  const onGeoCoordsCallbackRef = useRef(null);
  onGeoCoordsCallbackRef.current = onGeoCoordsCallback;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isReady) {
      let watchId;
      if (navigator.geolocation) {
        watchId = navigator.geolocation.watchPosition(
          (position: GeolocationPosition) => {
            onGeoCoordsCallbackRef.current(position.coords);
            dispatch({
              type: 'BROWSER_GEO_LOCATION_ACCESS',
              payload: true,
            });
          },
          () => {
            console.log('refused');
            dispatch({
              type: 'BROWSER_GEO_LOCATION_ACCESS',
              payload: false,
            });
          }
        );
      }
      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    }
  }, [onGeoCoordsCallbackRef, dispatch, isReady]);
};

export { useUserLocation };
