import { useModernNavBarContext } from '@ee-monorepo/shared/utilities/hooks';
import { useEffect } from 'react';
import s from './slices-area.module.scss';
import cn from 'classnames';
import { Slice } from '@prismicio/types';
import { components } from '@ee-monorepo/prismic/feature-slices/all-slices';
import { SliceZone } from '@prismicio/react';

interface SlicesAreaProps {
  slices: Slice[];
  className?: string;
}

export function SlicesArea({ slices, className }: SlicesAreaProps) {
  const { setModernNavBarEnabled } = useModernNavBarContext();
  useEffect(() => {
    setModernNavBarEnabled(true);
  }, [setModernNavBarEnabled]);

  return (
    <div
      data-testid="slices-area"
      className={cn('bg-SnapSand', s.slicesArea, className)}
    >
      <SliceZone slices={slices} components={components} />
    </div>
  );
}
