export const navAndToggleHeight = 140;

export const directorySheetBreaks = {
  top: () => ({
    enabled: true,
    height: window.innerHeight - navAndToggleHeight,
  }), // distance of mobile header + in-store/mobile toggle
  middle: () => ({ enabled: true, height: window.innerHeight / 2 - 120 }), // 120: to show the sheet a bit below zoom controls
  bottom: () => ({ enabled: true, height: 100 }),
};

export const heroSheetBreaks = {
  top: () => ({
    enabled: true,
    height: window.innerHeight - navAndToggleHeight,
  }), // distance of mobile header + in-store/mobile toggle
  middle: () => ({ enabled: true, height: window.innerHeight * 0.45 }), // 45%: sheet view height
  bottom: () => ({ enabled: false }),
};

export const mapSheetBreaks = {
  top: () => ({
    enabled: true,
    height: window.innerHeight - navAndToggleHeight,
  }), // distance of mobile header + in-store/mobile toggle
  middle: () => ({ enabled: true, height: window.innerHeight * 0.6 }), // 60%: sheet view height
  bottom: () => ({ enabled: true, height: 100 }),
};
