import dynamic from 'next/dynamic';

const InStoreMerchantsResults = dynamic(
  () =>
    import(
      '../../components/store-locator/merchants-results/in-store-merchants-results'
    ).then((module) => module.InStoreMerchantsResults),
  { ssr: false, loading: () => <div></div> }
);

export { InStoreMerchantsResults };
