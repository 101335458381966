import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { CupertinoPane } from 'cupertino-pane';
import { PaneSettings, PaneBreak } from 'cupertino-pane/dist/types/models';

export const MOBILE_SHEET_SELECTOR = '#mobile-sheet';

export interface MobilePanelProps {
  children: ReactNode;
  show: boolean;
  paneSettings?: Partial<PaneSettings>;
  initialBreakpoint?: 'top' | 'middle' | 'bottom';
  /* breaks not part of paneSettings, window is accessible only from useEffect */
  breaks?: {
    top: () => PaneBreak;
    middle: () => PaneBreak;
    bottom: () => PaneBreak;
  };
  topHeight?: number;
  middleHeight?: number;
  bottomHeight?: number;
}

export function MobileSheet({
  children,
  paneSettings,
  show,
  breaks,
  middleHeight,
  initialBreakpoint = 'middle',
}: MobilePanelProps) {
  const paneRef = useRef<CupertinoPane>(null);
  const currentSettings = useMemo(() => paneSettings || {}, [paneSettings]);

  // initialization
  useEffect(() => {
    if (!paneRef.current && show) {
      paneRef.current = new CupertinoPane(MOBILE_SHEET_SELECTOR, {
        buttonDestroy: false,
        breaks: {
          top: breaks.top(),
          middle: breaks.middle(),
          bottom: breaks.bottom(),
        },
        initialBreak: initialBreakpoint,
        events: {},
        ...currentSettings,
      });
      paneRef.current.present();
    }
    return () => {
      document.querySelector('body').style.overflow = ''; // clean up moving screen fix while swiping
    };
  }, [currentSettings, paneRef, show, breaks, initialBreakpoint]);

  /**
   * Recalculate breaks
   */
  const setBreaks = useCallback(async () => {
    if (breaks && paneRef.current) {
      const paneBreaks = {
        top: breaks.top(),
        middle: middleHeight
          ? {
              enabled: true,
              fitHeight: true,
              height: middleHeight,
            }
          : breaks.middle(),
        bottom: breaks.bottom(),
      };
      await paneRef.current.setBreakpoints(paneBreaks);
    }
  }, [breaks, middleHeight]);

  /* visibility on resize */
  useEffect(() => {
    if (paneRef.current) {
      paneRef.current.wrapperEl.classList.toggle('hidden', !show);
    }
    if (show) {
      // avoid moving screen while swiping
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = '';
    }
  }, [paneRef, show]);

  /* if breaks changed */
  useEffect(() => {
    // add small delay before relocating
    const timeout = setTimeout(() => {
      setBreaks();
      paneRef.current?.moveToBreak?.(initialBreakpoint);
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [breaks, setBreaks, initialBreakpoint]);

  /* breaks initialize */
  useEffect(() => {
    setBreaks();
  }, [setBreaks]);

  /* breaks reload on browser screen resize  */
  useEffect(() => {
    const onResize = () => {
      setBreaks();
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [setBreaks]);

  return (
    <div
      id="mobile-sheet"
      data-testid="mobile-sheet"
      className="pb-6 pt-4 px-4"
    >
      {show ? children : null}
    </div>
  );
}
