import Head from 'next/head';
import { GetServerSidePropsContext } from 'next';
import {
  GeolocatedResultProvider,
  StoreLocatorDesignContext,
  StoresLocatorProvider,
  selectResultsCity,
  selectResultsState,
} from '@ee-monorepo/store-locator/shared/data-access';
import { validateMerchantId } from '@ee-monorepo/shared/utilities/functions';
import { Shell } from '../components/shell';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import { createClient } from '../prismic-configuration';
import { PrismicDocument, Slice } from '@prismicio/types';
import { StoreLocatorV1 } from '../components/store-locator/main-page-v1';
import { StoreLocatorV2 } from '../components/store-locator/main-page-v2';
import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { useNewStoreLocatorExperiment } from '@ee-monorepo/growth-experiments';

export interface FindStoresProps {
  setSelectedLocationMock?: (merchantId: number) => void;
  slices: Slice[];
}

export function FindStores({
  setSelectedLocationMock,
  slices,
}: FindStoresProps) {
  const router = useRouter();
  const { industry, dbaName, merchantId, zipCode } = router.query as {
    [key: string]: string;
  };
  const decodedDbaName = useMemo(() => decodeURIComponent(dbaName), [dbaName]);
  const t = useTranslations();

  const city = useSelector(selectResultsCity);
  const state = useSelector(selectResultsState);
  const storeName = useCallback(
    () => decodedDbaName.replace(/-/g, ' '),
    [decodedDbaName]
  );
  const cityState = () =>
    city && state
      ? t('shared.city_state', { cityState: `${city}, ${state}` })
      : '';

  const formattedIndustry = () =>
    t(`shared.industries.${industry?.toUpperCase()}.label`);

  const { isNewStoreLocatorDesign, treatmentLoading } =
    useNewStoreLocatorExperiment();

  const canonicalURL = `${appConfig.siteUrl}${
    merchantId
      ? `/snap-merchants/${decodedDbaName}/${merchantId}`
      : industry !== 'ALL' && zipCode && industry
      ? `/find-stores/${state?.toLowerCase()}/${zipCode}/${industry?.toLowerCase()}`
      : router.asPath
  }`;

  const newCanonicalURL = `${appConfig.siteUrl}${
    !['', undefined, null].includes(merchantId) &&
    !['', undefined, null].includes(industry) &&
    !['', undefined, null].includes(decodedDbaName)
      ? `/snap-merchants/${industry?.toLowerCase()}/${decodedDbaName}/${merchantId}`
      : industry !== 'ALL' && zipCode && industry
      ? `/find-stores/${state?.toLowerCase()}/${zipCode}/${industry?.toLowerCase()}`
      : router.asPath
  }`;

  const alternateURL = `${
    appConfig.siteUrl
  }${`/find-stores?merchantId=${merchantId}&dbaName=${decodedDbaName}`}`;

  return (
    <>
      <Head>
        <title>
          {dbaName
            ? t.rich('page.metadata.in_store_page_title_with_merchant', {
                storeName,
                cityState,
              })
            : industry
            ? t.rich('page.metadata.in_store_page_title_with_content', {
                industry: formattedIndustry,
                cityState,
              })
            : t('page.metadata.in_store_page_title_default')}
        </title>
        <link
          rel="canonical"
          href={validateMerchantId(merchantId) ? newCanonicalURL : canonicalURL}
        />
        {industry !== 'ALL' || merchantId ? (
          <link rel="alternate" href={`${appConfig.siteUrl}${router.asPath}`} />
        ) : null}
        {merchantId ? (
          <link
            rel="alternate"
            href={validateMerchantId(merchantId) ? canonicalURL : alternateURL}
          />
        ) : null}
        <meta
          name="description"
          content={
            dbaName
              ? `${t.rich(
                  'page.metadata.in_store_page_description_with_merchant',
                  {
                    storeName,
                    cityState,
                  }
                )}`
              : industry
              ? `${t.rich(
                  'page.metadata.in_store_page_description_with_content',
                  {
                    industry: formattedIndustry,
                  }
                )}`
              : `${t('page.metadata.in_store_page_description_default')}`
          }
        />
        {industry && !merchantId ? (
          <meta
            name="keywords"
            content={t(`shared.industries.${industry}.keywords`)}
          />
        ) : null}
      </Head>
      <Shell>
        <StoreLocatorDesignContext.Provider value={isNewStoreLocatorDesign}>
          {!treatmentLoading ? (
            isNewStoreLocatorDesign ? (
              <StoreLocatorV2 />
            ) : (
              <StoreLocatorV1 slices={slices} />
            )
          ) : null}
        </StoreLocatorDesignContext.Provider>
      </Shell>
    </>
  );
}

export default function Page({ slices }) {
  return (
    <StoresLocatorProvider>
      <GeolocatedResultProvider>
        <FindStores slices={slices} />
      </GeolocatedResultProvider>
    </StoresLocatorProvider>
  );
}

export async function getServerSideProps({
  locale,
  previewData,
}: GetServerSidePropsContext) {
  let queryResult: PrismicDocument = null;

  const client = createClient({ previewData });
  try {
    queryResult = await client.getByUID<any>('store_locator', 'store-locator');
  } catch (e) {
    console.error(e);
  }
  return {
    props: {
      slices: queryResult?.data?.slices ?? [],
      messages: {
        page: { ...require(`../i18n/pages/store-locator/${locale}.json`) },
        shared: { ...require(`../i18n/shared/${locale}.json`) },
      },
    },
  };
}
